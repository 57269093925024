.ng-wig {
  font-family: $base-font-family;

  ol:not([class]) {
    margin-left: 1.25rem;
    list-style: decimal;
  }

  ul:not([class]) {
    margin-left: 1.25rem;
    list-style: disc;
  }

  b {
    font-weight: var(--font-weight-bold);
  }

  i {
    font-style: italic;
  }

  .nw-editor-container {
    padding: 0.25rem;
  }
}
